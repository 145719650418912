<template>
  <div>
    <Loader color="#FF035A" size="50" v-if="$apollo.queries.user.loading" />
    <div class="container" v-else>
      <div>
        <span class="status">
          Status:
          <span>{{ status }}</span>
          <div class="check" v-if="user.payouts_enabled">
            <img src="../assets/icons/Check.svg" />
          </div>
        </span>
        <div class="messages">
          <span v-if="user.charges_enabled && !user.payouts_enabled">
            You need to add a Bank Account or a Debit Card to enable Payouts
          </span>
        </div>
        <div class="buttons" v-if="!user.charges_enabled">
          <button
            class="primary"
            @click="getStripeOnboardingLink"
            :disabled="submitting"
          >
            {{ submitting ? "Loading..." : "Complete Stripe Onboarding" }}
          </button>
        </div>
        <div v-if="user.charges_enabled">
          <div
            class="accounts-loader"
            v-if="$apollo.queries.externalAccounts.loading"
          >
            <Loader color="#FF035A" size="50" />
          </div>
          <div class="external-accounts" v-else>
            <div class="accounts">
              <div class="title">Debit Cards</div>
              <div class="top">
                <span class="saved-text">Saved Cards</span>
                <div @click="showModal = 'add-card'" class="add-account">
                  <div class="plus-icon">
                    <img src="../assets/icons/Plus-Accent.svg" />
                  </div>
                  Add Card
                </div>
              </div>
              <div class="accounts-list" v-if="cards && cards.length">
                <div
                  v-for="card in cards"
                  :key="card.id"
                  class="account-container"
                >
                  <Card :card="card" />
                </div>
              </div>
              <div class="no-account" @click="showModal = 'add-card'" v-else>
                <div class="plus-icon">
                  <img src="../assets/icons/Plus-Accent.svg" />
                </div>
                <span>Add Card</span>
              </div>
            </div>
            <div class="accounts">
              <div class="title">Bank Accounts</div>
              <div class="top">
                <span class="saved-text">Saved Cards</span>
                <div @click="showModal = 'add-card'" class="add-account">
                  <div class="plus-icon">
                    <img src="../assets/icons/Plus-Accent.svg" />
                  </div>
                  Add Bank Account
                </div>
              </div>
              <div
                class="accounts-list"
                v-if="bankAccounts && bankAccounts.length"
              >
                <div
                  v-for="account in bankAccounts"
                  :key="account.id"
                  class="account-container"
                >
                  <BankAccount :val="account" />
                </div>
              </div>
              <div class="no-account" @click="showModal = 'add-card'" v-else>
                <div class="plus-icon">
                  <img src="../assets/icons/Plus-Accent.svg" />
                </div>
                <span>Add Account</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddCard
        :is-payouts="true"
        v-if="showModal === 'add-card'"
        @close="showModal = ''"
      />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Card from "@/components/Card.vue";
import BankAccount from "@/components/BankAccount.vue";
import AddCard from "@/components/modals/AddCard.vue";

import GET_USER from "@/api/queries/GET_USER.gql";
import GET_EXTERNAL_ACCOUNTS from "@/api/queries/GET_EXTERNAL_ACCOUNTS.gql";
import GET_USER_UPDATE from "@/api/subscriptions/GET_USER_UPDATE.gql";
import STRIPE_ONBOARDING from "@/api/mutations/STRIPE_ONBOARDING.gql";

export default {
  name: "Payouts",
  components: { Loader, Card, AddCard, BankAccount },
  data() {
    return {
      url: "",
      submitting: false,
      showModal: "",
      cards: [],
      bankAccounts: []
    };
  },
  watch: {
    externalAccounts(all) {
      this.cards = all.filter(el => el.__typename === "StripeCard");
      this.bankAccounts = all.filter(el => el.__typename === "StripeBank");
    }
  },
  computed: {
    status() {
      const { user } = this;
      if (!user) return "";
      if (user.payouts_enabled) return "Payouts Enabled";
      if (user.charges_enabled) return "Charges Enabled";
      if (!user.connected_stripe_account_id) return "Not Onboarded yet";
      return "Onboarding not Completed";
    }
  },
  methods: {
    getStripeOnboardingLink() {
      this.submitting = true;
      return this.$apollo.mutate({
        mutation: STRIPE_ONBOARDING,
        update: (_, { data: { stripe_create_account } }) => {
          window.location.href = stripe_create_account.url;
        }
      });
    }
  },
  apollo: {
    user: {
      query: GET_USER,
      variables() {
        return { id: this.$store.getters["account/getUserId"] };
      },
      update({ users_by_pk }) {
        return users_by_pk;
      },
      subscribeToMore: {
        document: GET_USER_UPDATE,
        variables() {
          return { id: this.$store.getters["account/getUserId"] };
        },
        updateQuery(previous, update) {
          return {
            ...previous,
            users_by_pk: update.subscriptionData.data.users_by_pk
          };
        }
      }
    },
    externalAccounts: {
      query: GET_EXTERNAL_ACCOUNTS,
      variables() {
        return { id: this.$store.getters["account/getUserId"] };
      },
      update({ stripe_get_external_accounts }) {
        return stripe_get_external_accounts;
      },
      skip() {
        return !(this.user || {}).charges_enabled;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 184px;
  color: $bg-dark;
  .status {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -1.025px;
    span {
      color: $accent;
      margin-left: 0.2rem;
    }
    .check {
      width: 18px;
      height: 18px;
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $accent;
      overflow: hidden;
      border-radius: 50%;
      img {
        object-fit: cover;
        width: 10px;
      }
    }
  }
  .messages {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    color: $text-additional;
    span {
      margin-top: 0.25rem;
    }
  }
  .buttons {
    margin: 1rem 0;
    display: flex;
    button {
      margin-right: 0.7rem;
      padding: 0.8rem 2rem;
    }
  }
  .accounts-loader {
    margin-top: 3rem;
    width: 50px;
    height: 50px;
  }
  .external-accounts {
    .accounts {
      width: 100%;
      margin-top: 1.5rem;
      .title {
        color: $accent;
        margin-bottom: 0.3rem;
      }
      .top {
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: black;
        .saved-text {
          flex: 1;
        }
        .add-account {
          display: flex;
          align-items: center;
          cursor: pointer;
          .plus-icon {
            margin-right: 4px;
          }
        }
      }
      .accounts-list {
        display: flex;
        margin-top: 4px;
        padding-bottom: 5px;
        overflow-x: auto;
        scrollbar-width: thin;
        scrollbar-color: $accent rgba(#cdcccc, 0.3);
        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          @include background-opacity(#cdcccc, 0.3);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $accent;
          border-radius: 5px;
        }
        .card-container,
        .account-container {
          margin-right: 16px;
        }
      }
      .no-account {
        width: 144px;
        height: 94px;
        padding: 13px;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #2a2a2a;
        border: 3px solid transparent;
        border-radius: 10px;
        color: $white;
        cursor: pointer;
        .plus-icon {
          margin-bottom: 0.4rem;
          img {
            height: 11px;
            width: 11px;
          }
        }
      }
    }
  }
}
</style>
