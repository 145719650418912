<template>
  <div>
    <Loader
      v-if="['cards', 'subscription'].some(el => $apollo.queries[el].loading)"
      color="#FF035A"
      size="50"
    />
    <div class="container" v-else>
      <div class="title">
        Current Plan:<span>{{ isPro ? "Pro" : "Base" }}</span>
      </div>
      <div class="next-billing" v-if="nextBilling">
        Next Billing:<span>{{ nextBilling }}</span>
      </div>
      <div class="next-billing" v-if="periodToCancellation">
        You will have Pro until:<span>{{ periodToCancellation }}</span>
      </div>
      <div class="buttons">
        <button
          class="primary"
          v-if="!isPro"
          @click="subscribe"
          :disabled="submitting"
        >
          {{ submitting ? "Upgrading..." : "Upgrade" }}
        </button>
        <button
          class="primary outline"
          v-if="isPro && !periodToCancellation"
          @click="cancelSubscription"
          :disabled="submitting"
        >
          {{ submitting ? "Cancelling..." : "Cancel Subscription" }}
        </button>
        <button
          class="primary"
          v-if="periodToCancellation"
          @click="resubscribe"
          :disabled="submitting"
        >
          {{ submitting ? "Reactivating..." : "Reactivate Subscription" }}
        </button>
      </div>
      <div class="cards">
        <div class="title">
          <span class="saved-text">Saved Cards</span>
          <div @click="showModal = 'add-card'" class="add-card">
            <div class="plus-icon">
              <img src="../assets/icons/Plus-Accent.svg" />
            </div>
            Add Card
          </div>
        </div>
        <div class="cards-list" v-if="cards && cards.length">
          <div v-for="card in cards" :key="card.id" class="card-container">
            <Card :card="card" />
          </div>
        </div>
        <div class="no-card" @click="showModal = 'add-card'" v-else>
          <div class="plus-icon">
            <img src="../assets/icons/Plus-Accent.svg" />
          </div>
          <span>Add Card</span>
        </div>
      </div>
      <AddCard
        :charges-enabled="chargesEnabled"
        v-if="showModal === 'add-card'"
        @close="closeModal"
      />
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import Loader from "@/components/Loader.vue";
import AddCard from "@/components/modals/AddCard.vue";

import GET_CARDS from "@/api/queries/GET_CARDS.gql";
import GET_SUBSCRIPTION from "@/api/queries/GET_SUBSCRIPTION.gql";
import GET_EXTERNAL_ACCOUNTS from "@/api/queries/GET_EXTERNAL_ACCOUNTS.gql";
import GET_USER from "@/api/queries/GET_USER.gql";
import GET_USER_UPDATE from "@/api/subscriptions/GET_USER_UPDATE.gql";
import SUBSCRIBE from "@/api/mutations/SUBSCRIBE.gql";
import RESUBSCRIBE from "@/api/mutations/RESUBSCRIBE.gql";
import CANCEL_SUBSCRIPTION from "@/api/mutations/CANCEL_SUBSCRIPTION.gql";

export default {
  name: "Subscription",
  components: { Card, Loader, AddCard },
  data() {
    return {
      showModal: "",
      submitting: false,
      subscribeNext: false
    };
  },
  computed: {
    isPro() {
      const { subscription = {} } = this;
      return ![
        "inactive",
        "unpaid",
        "canceled",
        "incomplete_expired",
        undefined
      ].includes(subscription.status);
    },
    nextBilling() {
      const { subscription = {}, isPro } = this;
      if (isPro && !subscription.cancel_at_period_end)
        return this.getDateStripe(subscription.current_period_end);
      return "";
    },
    periodToCancellation() {
      const { subscription = {}, isPro } = this;
      if (isPro && subscription.cancel_at_period_end)
        return this.getDateStripe(subscription.current_period_end);
      return "";
    },
    chargesEnabled() {
      return this.user?.charges_enabled;
    }
  },
  methods: {
    getDateStripe(timeStamp) {
      const date = new Date(timeStamp * 1000);
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
    },
    closeModal() {
      this.showModal = "";
      if (this.subscribeNext) this.subscribe();
    },
    updateSubscription(store, subscription) {
      const data = store.readQuery({ query: GET_SUBSCRIPTION });
      data.stripe_get_subscription = subscription;
      this.submitting = false;
    },
    subscribe() {
      this.subscribeNext = false;

      if (!this.cards || !this.cards.length) {
        this.subscribeNext = true;
        return (this.showModal = "add-card");
      }

      this.submitting = true;
      this.$apollo.mutate({
        mutation: SUBSCRIBE,
        update: (store, { data: { stripe_subscribe } }) => {
          this.updateSubscription(store, stripe_subscribe);
        }
      });
    },
    cancelSubscription() {
      this.submitting = true;
      this.$apollo.mutate({
        mutation: CANCEL_SUBSCRIPTION,
        update: (store, { data: { stripe_cancel_subscription } }) => {
          this.updateSubscription(store, stripe_cancel_subscription);
        }
      });
    },
    resubscribe() {
      this.submitting = true;
      this.$apollo.mutate({
        mutation: RESUBSCRIBE,
        update: (store, { data: { stripe_resume_subscription } }) => {
          this.updateSubscription(store, stripe_resume_subscription);
        }
      });
    }
  },
  apollo: {
    cards: {
      query: GET_CARDS,
      update: ({ stripe_get_cards }) => stripe_get_cards
    },
    subscription: {
      query: GET_SUBSCRIPTION,
      update: ({ stripe_get_subscription }) => stripe_get_subscription
    },
    externalAccounts: {
      query: GET_EXTERNAL_ACCOUNTS,
      update({ stripe_get_external_accounts }) {
        return stripe_get_external_accounts;
      }
    },
    user: {
      query: GET_USER,
      variables() {
        return { id: this.$store.getters["account/getUserId"] };
      },
      update({ users_by_pk }) {
        return users_by_pk;
      },
      subscribeToMore: {
        document: GET_USER_UPDATE,
        variables() {
          return { id: this.$store.getters["account/getUserId"] };
        },
        updateQuery(previous, update) {
          return {
            ...previous,
            users_by_pk: update.subscriptionData.data.users_by_pk
          };
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 184px;
  color: $bg-dark;
  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -1.025px;
    span {
      color: $accent;
      margin-left: 0.2rem;
    }
  }
  .next-billing {
    margin-top: 0.5rem;
    color: $text-additional;
    span {
      color: $text;
      margin-left: 0.2rem;
    }
  }
  .buttons {
    margin-top: 1rem;
    display: flex;
    button {
      margin-right: 0.7rem;
      padding: 0.8rem 2rem;
    }
  }
  .cards {
    margin-top: 3rem;
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: -0.22px;
      color: black;
      .saved-text {
        flex: 1;
      }
      .add-card {
        display: flex;
        align-items: center;
        cursor: pointer;
        .plus-icon {
          margin-right: 4px;
        }
      }
    }
    .cards-list {
      display: flex;
      margin-top: 8px;
      padding-bottom: 5px;
      overflow-x: auto;
      scrollbar-width: thin;
      scrollbar-color: $accent rgba(#cdcccc, 0.3);
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        @include background-opacity(#cdcccc, 0.3);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $accent;
        border-radius: 5px;
      }
      .card-container {
        margin-right: 16px;
      }
    }
    .no-card {
      width: 144px;
      height: 94px;
      padding: 13px;
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #2a2a2a;
      border: 3px solid transparent;
      border-radius: 10px;
      color: $white;
      cursor: pointer;
      .plus-icon {
        margin-bottom: 0.4rem;
        img {
          height: 11px;
          width: 11px;
        }
      }
    }
  }
}
</style>
