<template>
  <div
    class="bank-account"
    :class="{
      selected: selectedAccount === val.id,
      notselected: selectedAccount && selectedAccount !== val.id
    }"
  >
    <div class="holder-name">{{ val.account_holder_name }}</div>
    <div class="bank-name">{{ val.bank_name }}</div>
    <div class="status">{{ val.status | capitalize }}</div>
  </div>
</template>

<script>
export default {
  name: "BankAccount",
  props: {
    val: Object,
    selectedAccount: String
  },
  filters: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bank-account {
  width: 144px;
  height: 94px;
  padding: 7px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #2a2a2a;
  border: 3px solid transparent;
  border-radius: 10px;
  color: $white;
  font-size: 10px;
  line-height: 24px;
  letter-spacing: -0.22px;
  transition: 0.2s;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.selected {
    border: 3px solid $accent;
    box-sizing: border-box;
  }
  &.notselected {
    color: $text-additional;
    .holder-name {
      opacity: 0.3;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  .holder-name {
    flex: 1;
    font-weight: bold;
  }
  .bank-name {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .status {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 10px;
    color: $accent;
    font-weight: bold;
  }
}
</style>
