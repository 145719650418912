<template>
  <div class="team">
    <Loader v-if="$apollo.queries.team.loading" color="#FF035A" size="50" />
    <div v-else class="content">
      <div class="invite-container" v-if="canUpdateTeam">
        <div class="title">
          <span v-if="team.company">
            Invite user to {{ team.company.name }}
          </span>
          <span v-else>Invite your team</span>
          <div class="plus-icon">
            <img src="../assets/icons/Plus-Accent.svg" />
          </div>
        </div>
        <div class="invite">
          <input
            type="email"
            class="text-input"
            placeholder="Email"
            v-model="email"
          />
          <CustomSelect
            :val="role"
            default="Representative"
            @setValue="value => (role = value)"
            :options="[
              { value: 'representative', label: 'Representative' },
              { value: 'artist', label: 'Artist' }
            ]"
            no-margin
          />
          <CustomSelect
            :val="access"
            default="Contributor"
            @setValue="value => (access = value)"
            :options="[
              { value: 'contributor', label: 'Contributor' },
              { value: 'owner', label: 'Owner' }
            ]"
            no-margin
          />
          <input
            type="text"
            class="text-input"
            placeholder="Company"
            v-model="companyName"
            v-if="!team.company"
          />
          <button
            type="submit"
            class="primary"
            :disabled="!invitationValid || submitting"
            @click="invite"
          >
            {{ submitting ? "Submitting..." : "Invite User" }}
          </button>
        </div>
      </div>
      <div class="teammates">
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Relationship</th>
              <th colspan="2">Contract Access</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{ id, role, access, user } in team.users" :key="id">
              <td>{{ user.email }}</td>
              <td>{{ role | capitalize }}</td>
              <td>{{ access | capitalize }}</td>
              <td v-if="removing.includes(id)">
                <Loader color="#FF035A" size="24" />
              </td>
              <td v-else-if="canUpdateTeam && userId !== user.id">
                <div class="remove-container">
                  <button class="remove" @click="removeMember(id)">
                    <span class="minus" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "@/components/Select.vue";
import Loader from "@/components/Loader.vue";

import GET_TEAM from "@/api/queries/GET_TEAM.gql";
import ADD_TEAM_MEMBER from "@/api/mutations/ADD_TEAM_MEMBER.gql";
import REMOVE_TEAM_MEMBER from "@/api/mutations/REMOVE_TEAM_MEMBER.gql";

const initialState = () => ({
  email: "",
  role: "representative",
  access: "contributor",
  companyName: "",
  submitting: false,
  removing: []
});

export default {
  name: "TeamManagement",
  components: {
    CustomSelect,
    Loader
  },
  data: () => initialState(),
  filters: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  computed: {
    canUpdateTeam() {
      const {
        team: { users = [] }
      } = this;
      const user_id = this.$store.getters["account/getUserId"];

      const index = users.findIndex(
        ({ access, user: { id } }) => access === "owner" && id === user_id
      );

      return index > -1;
    },
    invitationValid() {
      if (!this.team.company && !this.companyName) return false;
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    userId() {
      return this.$store.getters["account/getUserId"];
    }
  },
  methods: {
    formatInvitation() {
      const { email, role, access, team, companyName } = this;
      const artist_id = this.$store.getters["account/getArtistId"];
      const user_id_inviter = this.$store.getters["account/getUserId"];

      const companyObj = team.company
        ? { company_id: team.company.id }
        : {
            company: {
              data: { name: companyName },
              on_conflict: {
                update_columns: ["name"],
                constraint: "companies_name_key"
              }
            }
          };

      return {
        user_id_inviter,
        artist_id,
        access,
        role,
        ...companyObj,
        user: {
          data: {
            email,
            status: "invited",
            display_name: email.split("@")[0]
          },
          on_conflict: {
            update_columns: ["email"],
            constraint: "users_email_key",
            where: { email: { _eq: email } }
          }
        }
      };
    },
    invite() {
      this.submitting = true;
      const invitation = this.formatInvitation();
      this.$apollo.mutate({
        mutation: ADD_TEAM_MEMBER,
        variables: { invitation },
        update: (
          store,
          {
            data: {
              insert_artist_users_one: { company, ...added }
            }
          }
        ) => {
          const data = store.readQuery({
            query: GET_TEAM,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          data.artist_users = [...data.artist_users, added];
          data.companies = [company];

          store.writeQuery({
            query: GET_TEAM,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          Object.assign(this.$data, initialState());
        }
      });
    },
    removeMember(id) {
      this.removing.push(id);
      this.$apollo.mutate({
        mutation: REMOVE_TEAM_MEMBER,
        variables: { id },
        update: (store, { data: { delete_artist_users_by_pk } }) => {
          const data = store.readQuery({
            query: GET_TEAM,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          data.artist_users = data.artist_users.filter(
            ({ id }) => id !== delete_artist_users_by_pk.id
          );

          if (!data.artist_users.find(({ role }) => role === "representative"))
            data.companies = [];

          store.writeQuery({
            query: GET_TEAM,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.removing = this.removing.filter(el => el !== id);
        }
      });
    }
  },
  apollo: {
    team: {
      query: GET_TEAM,
      variables() {
        return { artistId: this.$store.getters["account/getArtistId"] };
      },
      update: ({ artist_users, companies }) => {
        return {
          users: artist_users,
          company: companies[0]
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.team {
  width: 100%;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    padding: 60px 184px;
    color: $bg-dark;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
    .title {
      display: flex;
      align-items: center;
      span {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -1.025px;
      }
      .plus-icon {
        margin-left: 8px;
      }
    }
    .invite {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.5rem;
      input {
        height: 40px;
      }
      input,
      button,
      .custom-select {
        margin: 0.5rem;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .teammates {
      margin-top: 32px;
      table {
        text-align: left;
        width: 100%;
        td {
          padding: 8px 0;
        }
        .remove-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .remove {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #e0e0e0;
            border-radius: 50%;
            cursor: pointer;
            outline: none;
            border: none;
            &:hover {
              background: $accent;
            }
            .minus {
              background: $white;
              width: 8px;
              height: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
